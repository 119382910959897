import styles from './login.module.css'

import LeftSideBar from "../../components/leftSideBar/leftSideBar";
import Header from "../../components/header/header";
import {useState} from "react";
import {PageNames} from "../../common/constants";
import Head from "next/head";
import LoginBody from "../../components/login/loginBody/loginBody";

export default function Login() {
  const [sideBarOpen, setSideBarOpen] = useState(false);

  return (
    <>
      <Head>
        <title>Sports Fever - Login</title>
      </Head>
      <div className={styles.LoginMainDiv}>
        <LeftSideBar sideBarOpen={sideBarOpen} setSideBarOpen={() => setSideBarOpen(!sideBarOpen)} pageName={PageNames.home}/>
        <Header setSideBarOpen={() => setSideBarOpen(!sideBarOpen)} sideBarOpen={sideBarOpen}/>
        <LoginBody />
      </div>
    </>
  );
}
