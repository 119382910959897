import styles from "../../forumTable/forumTable.module.css";
import React from "react";
import LoginForm from "../loginForm/loginForm";

export default function LoginBody() {
  return (
    <div className={styles.ForumTableMain}>
      <div className={styles.MainContentDiv}>
        <div className={styles.ForumListDiv}>
          <div className={styles.ForumHeadingDiv}>
            <h1 className={styles.ForumHeading}>
              {'Sign In'}
            </h1>
          </div>
          <LoginForm />
        </div>
      </div>
    </div>
  );
}