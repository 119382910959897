import styles from './loginForm.module.css';

import { axiosClient } from '../../../common/api';
import { renderTextField } from '../../../common/form/components'
import { loginSchema, initialValues } from '../../../common/validation/login'

import { Formik, Form } from "formik";
import {NotificationManager} from 'react-notifications';

import {LoginApiInterface} from "../../../common/types/api/login";
import {useDispatch} from "react-redux";
import {setUser} from "../../../store/user";
import {useRouter} from "next/router";
import {AxiosError} from 'axios';

export default function LoginForm() {
  const router = useRouter();
  const dispatch = useDispatch();

  const onSubmit = async (values: any) => {
    const data: LoginApiInterface = { ...values };

    try {
      const response = await axiosClient.post('/auth/login', data);

      NotificationManager.success('Login Successful', 'Sign In', 3000);

      const { foundUser, token } = response.data.data;

      dispatch(setUser({ ...foundUser, token }));

      await router.push('/');
    } catch (error: any) {
      const axiosError: AxiosError = error;
      const data = axiosError?.response?.data as any;
      NotificationManager.error(data?.error, data?.errorMessage, 3000);
    }
  }

  return (
    <div className={styles.LoginFormMainDiv}>
      <Formik
        initialValues={initialValues}
        validationSchema={loginSchema}
        onSubmit={async (values, { resetForm }) => {
          await onSubmit(values);
          resetForm();
        }}
      >
        <Form className={styles.LoginForm}>
          {renderTextField('email', 'text', 'Email', 'Email')}
          {renderTextField('password', 'password', 'Password', 'Password')}
          <button className={styles.LoginButton} type={'submit'}>Sign In</button>
        </Form>
      </Formik>
    </div>
  )
}